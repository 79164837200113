import { CircularProgress, Fab, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Grid, IconButton, Link, Tooltip, Typography, Button, Checkbox, FormControlLabel, Badge } from "@material-ui/core";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../Context/NotificationContext";
import { UserContext } from "../../Context/UserContext";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import SurveyService from "./../../Services/SurveyService";
import { makeStyles } from '@material-ui/core/styles';
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import { FormattedMessage, useIntl } from "react-intl";
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DocumentIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import LabelIcon from '@material-ui/icons/Label';
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyTypeLabel from "../Translation/SurveyTypeLabel";
import CompanyService from "../../Services/CompanyService";
import TitleIcon from '@material-ui/icons/Title';
import ReportIcon from '@material-ui/icons/Report';

const useStyles = makeStyles((theme) => ({
    centeredHeader: {
        '& > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textAlign: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    buttonHeader: {
        maxWidth: '25px'
    },
    centeredCell: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    error: {
        backgroundColor: theme.palette.error.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        borderRadius: 5,
        padding: 5,
        color: '#ffffff',
        margin: 2,
    },
    infoColor: {
        color: theme.palette.info.main
    },
    infoBadge: {
        backgroundColor: theme.palette.info.main,
        color: '#fff',
        opacity: 0.7,
        '@media print' : {
            display: 'none'
        }
    },
}));


export default function Surveys() {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const [ brcgsRegisterNoticeOpen, setBrcgsRegisterNoticeOpen ] = useState('1' === searchParams.get('brcgsRegisterNotice'));
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [closeSkipWorkflow, setCloseSkipWorkflow] = useState(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [closeCombinedHash, setCloseCombinedHash] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [importMessagesDialogOpen, setImportMessagesDialogOpen] = useState(false);
    const [importMessages, setImportMessages] = useState([]);
    const [deleteCombinedHash, setDeleteCombinedHash] = useState(null);
    const [company, setCompany] = useState(null);

    const classes = useStyles();
    const { updateTitle } = useContext(AppBarTitleContext);
    const { user } = useContext(UserContext);
    const intl = useIntl();

    const companyId = searchParams.get('company');
    const urlParamSearch = searchParams.get('search') ? searchParams.get('search') : null;

    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {name: 'lastRespondentAt', direction: 'desc'},
        data: [],
        searchText: ''
    });

    const columns = [{
            name: 'id',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                sort: false,
                searchable: false,
                print: false,
                download: false
            }
        },
        {
            name: 'attributes.combinedHash',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                sort: false,
                searchable: false,
                print: false,
                download: false
            }
        },
        {
            label: intl.formatMessage({id: 'surveys.surveyTitleColumn', defaultMessage: 'Survey title'}),
            name: 'attributes.title',
            options: {
                customBodyRender: (value, tableMeta) => {
                    let title = value;
                    if (tableMeta.rowData[15] && tableMeta.rowData[15]['survey-title']) {
                        title = tableMeta.rowData[15]['survey-title'].data.title;
                        if (user && user.role && user.role === 'admin') {
                            title = <Tooltip title="Overridden" placement="right"><Badge
                                badgeContent={'i'}
                                overlap="rectangular"
                                classes={{ badge: classes.infoBadge }}
                            >
                                {title}
                            </Badge></Tooltip>
                        }
                    }
                    // NOTE: If minimum respondents change in backend, they need to be updated here
                    if (tableMeta.rowData[4] && (
                        (tableMeta.rowData[3] === 'brcgs' && tableMeta.rowData[4] >= 5) ||
                        (tableMeta.rowData[3] === 'brcgs-free' && tableMeta.rowData[4] >= 4) ||
                        (tableMeta.rowData[3] === 'ssafe' && tableMeta.rowData[4] >= 5) ||
                        (tableMeta.rowData[3] === 'premium' && tableMeta.rowData[4] >= 5)
                    )) {
                        let themeOverride = 'default';
                        if (-1 !== ['brcgs', 'brcgs-free'].indexOf(tableMeta.rowData[3])) {
                            themeOverride = 'brcgs';
                        } else if ('ssafe' === tableMeta.rowData[3]) {
                            themeOverride = 'ssafe';
                        }
                        if (user && user.role && user.role === 'brcgs-admin') {
                            return title;
                        } else {
                            return <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/survey-report/'+tableMeta.rowData[1]+(tableMeta.rowData[3] === 'premium' ? '/summary' : '/basic-data')+(themeOverride ? '?theme='+themeOverride : '')}>{title}</Link>;
                        }
                    } else if (user && user.role && user.role !== 'brcgs-admin') {
                        return <Tooltip title={intl.formatMessage({id: 'surveys.noReportDataTooltip', defaultMessage: 'No report data for this survey'})} placement="right"><span>{title}</span></Tooltip>;
                    } else {
                        return title;
                    }
                }
            }
        },
        {
            name: 'attributes.type',
            label: intl.formatMessage({id: 'surveys.typeColumn', defaultMessage: 'Type'}),
            options: {
                customBodyRender: (value) => (<SurveyTypeLabel type={value} />)
            }
        },
        {
            name: 'attributes.scoredRespondentCount',
            label: intl.formatMessage({id: 'surveys.responsesColumn', defaultMessage: 'Responses'}),
            options: {
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredHeader,
                })
            }
        },
        {
            name: 'attributes.providerId',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                sort: false,
                searchable: false,
                print: false,
                download: false
            }
        },
        {
            name: 'attributes.providerStatus',
            label: intl.formatMessage({id: 'surveys.statusColumn', defaultMessage: 'Status'}),
            options: {
                display: (user && user.role && (user.role === 'admin' || user.role === 'brcgs-admin') ? true : false), // Only show survey provider status for admins and brcgs-admins
                customBodyRender: value => (
                    value ? value.charAt(0).toUpperCase() + value.slice(1) : null
                )
            }
        },
        {
            name: 'relationships.company.data.name',
            label: intl.formatMessage({id: 'surveys.companyColumn', defaultMessage: 'Company'}),
            options: {
                display: (user && user.role && (user.role === 'admin' || user.role === 'brcgs-admin' ) ? true : false) // Only show company for admins and brcgs-admins
            }
        },
        {
            name: 'relationships.sites',
            label: intl.formatMessage({id: 'surveys.siteColumn', defaultMessage: 'Site'}),
            options: {
                display: (user && user.role && user.role === 'brcgs-admin' ? true : false), // Only show company for brcgs-admin users
                customBodyRender: (value) => {
                    if (value && value.length && value[0].data && value[0].data.name) {
                        return value[0].data.name;
                    }
                    return null;
                }
            }
        },
        {
            name: 'attributes.provider',
            label: intl.formatMessage({id: 'surveys.providerColumn', defaultMessage: 'Provider'}),
            options: {
                sort: false,
                display: (user && user.role && user.role === 'admin' ? true : false), // Only show survey provider for admins
                customBodyRender: (value, tableMeta) => {
                    // NOTE: If minimum respondents change in backend, they need to be updated here
                    if (value === 'alchemer_new') {
                        return <span>{tableMeta.rowData[5]} (Alc. 🇪🇺)</span>;
                    } else if (value === 'alchemer_old') {
                        return <span>{tableMeta.rowData[5]} (Alc. 🇺🇸)</span>;
                    } else {
                        return <span>{(value) ? value.charAt(0).toUpperCase() + value.slice(1) : null}</span>;
                    }
                },
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredHeader,
                })
            }
        },
        {
            name: 'relationships.survey-import-messages',
            label: 'Import message(s)',
            options: {
                sort: false,
                display: (user && user.role && user.role === 'admin' ? true : false), // Only show for admins
                customBodyRender: (value) => {
                    if (value && value.length) {
                        const infos = value.filter(m => m.data.level === 'info');
                        const warnings = value.filter(m => m.data.level === 'warning');
                        const errors = value.filter(m => m.data.level === 'error');
                        const content = [];
                        if (infos.length) {
                            content.push(<span key="info" className={classes.info} style={{whiteSpace: 'nowrap'}}>Info: {infos.length}</span>)
                        }
                        if (warnings.length) {
                            content.push(<span key="warn" className={classes.warning} style={{whiteSpace: 'nowrap'}}>Warning: {warnings.length}</span>)
                        }
                        if (errors.length) {
                            content.push(<span key="err" className={classes.error} style={{whiteSpace: 'nowrap'}}>Error: {errors.length}</span>);
                        }
                        return <span style={{cursor: 'pointer'}} onClick={() => {setImportMessagesDialogOpen(true); setImportMessages(value);}}>{content}</span>;
                    } else {
                        return null;
                    }
                },
            }
        },
        {
            name: 'attributes.lastRespondentAt',
            label: intl.formatMessage({id: 'surveys.lastResponseColumn', defaultMessage: 'Last response'}),
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'attributes.combinedHash',
            label: ' ',
            options: {
                sort: false,
                display: (user && user.role && user.role === 'admin' ? true : false),
                customBodyRender: (value, tableMeta) => {
                    const siteCode = tableMeta.rowData[8] && tableMeta.rowData[8].length && tableMeta.rowData[8][0].data.code ? tableMeta.rowData[8][0].data.code : 'NA';
                    const reportDate = (new Date(tableMeta.rowData[14] ? tableMeta.rowData[14] : tableMeta.rowData[13])).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'}).replaceAll(' ', '-');
                    return (
                        <div style={{minHeight: 43.5}}>{/* Keep rows equal-ish height if some do not have any icons */}
                            { (tableMeta.rowData[3] === 'premium') ?
                                <IconButton className={classes.rowButton} color="primary" component={RouterLink} to={'/surveys/'+value+'/rogue-responses'}>
                                    <Tooltip title="Rogue responses">
                                        <ReportIcon style={{color: '#ff9800'}} fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                            : null}
                            { (tableMeta.rowData[3] === 'premium' || tableMeta.rowData[3] === 'brcgs') && tableMeta.rowData[6] === 'in-progress' ?
                                <IconButton className={classes.rowButton} color="primary" component={RouterLink} to={'/surveys/'+value+'/progress-report'}>
                                    <Tooltip title="Progress reports">
                                        <AssignmentIcon fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                            : null}
                            {tableMeta.rowData[3] === 'brcgs' && tableMeta.rowData[6] === 'closed' ?
                                <Fragment>
                                    {tableMeta.rowData[3] === 'brcgs' ?
                                        <Tooltip title="PDF">
                                            <IconButton className={classes.rowButton} color="primary" onClick={() => {downloadReport(tableMeta.rowData[0], 'pdf', `CE_006_Site_Food_Safety_Culture_Report_${siteCode}_${reportDate}.pdf`)}}>
                                                <PdfIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    : null }
                                    {tableMeta.rowData[3] === 'brcgs' ?
                                        <Tooltip title="DOCX">
                                            <IconButton className={classes.rowButton} color="primary" onClick={() => {downloadReport(tableMeta.rowData[0], 'docx', `CE_005_Directory_Audit_Report_${siteCode}_${reportDate}.docx`)}}>
                                                <DocumentIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    : null}
                                </Fragment>
                            : null}
                            <Fragment>
                                {tableMeta.rowData[6] === 'in-progress' && tableMeta.rowData[3] === 'brcgs' ?
                                    <Tooltip title={(intl.formatMessage({id: 'generic.close', defaultMessage: 'Close'}))}>
                                        <IconButton className={classes.rowButton} color="primary" onClick={() => {setCloseCombinedHash(value); setCloseDialogOpen(true);}}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                : null}
                                <Tooltip title="Demographic labels">
                                    <IconButton className={[classes.infoColor, classes.rowButton]} component={RouterLink} to={'/surveys/'+value+'/option-labels'}>
                                        <LabelIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Title override">
                                    <IconButton className={classes.rowButton} component={RouterLink} to={'/surveys/'+value+'/title'}>
                                        <TitleIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Import history">
                                    <IconButton className={classes.rowButton} color="primary" component={RouterLink} to={'/surveys/'+value+'/revisions'}>
                                        <HistoryIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={(intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}))}>
                                    <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteCombinedHash(value); setDeleteDialogOpen(true)}}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Fragment>
                        </div>
                    )
                },
                setCellProps: () => ({
                    align: 'right'
                }),
            }
        },
        {
            name: 'attributes.createdAt',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'attributes.updatedAt',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'relationships',
            label: '',
            options: {
                display: false
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'surveys.noData', defaultMessage: 'No data...'}),
            }
        },
        pagination: tableData.count > 10, // Only show pagination if total is above pagination limit
        tableBodyHeight: 'auto',
        searchPlaceholder: intl.formatMessage({id: 'surveys.searchPlaceholder', defaultMessage: 'Survey title...'}),
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        searchText: urlParamSearch,
        customSearchRender: debounceSearchRender(500),
        filter: false,
        searchOpen: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'title';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    fetchSurveys(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'sort':
                    fetchSurveys(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'search':
                    fetchSurveys(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchSurveys(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText));
                    }
                    break;

                case 'changeRowsPerPage':
                    fetchSurveys(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const downloadReport = (uuid, type, title) => {
        setLoading(true);
        SurveyService.surveyStaticReport(uuid, type)
        .then(function (response) {
            let url = window.URL.createObjectURL(response.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = title;
            a.click();
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };

    const fetchSurveys = (page, pageSize, sort, search = null) => {
        search = search === '' ? null : search;
        const queryParams = [];
        if (companyId) {
            queryParams.push('company='+companyId);
        }
        if (search !== null) {
            queryParams.push('search='+search);
        }
        history.push({search: queryParams.join('&')});

        console.log('FETCH#', page, pageSize, search);
        const filter = {};
        if (companyId) {
            filter['company'] = companyId;
        }
        setLoading(true);
        SurveyService.surveysByHash(page*pageSize, pageSize, sort, search, filter)
        .then(function (response) {
            setTableData({
                sort: sort,
                data: response.data.data,
                count: response.data.meta.count,
                rowsPerPage: pageSize,
                searchText: search
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (companyId) {
            // Fetch company info
            CompanyService.company(companyId).then(function (response) {
                setCompany(response.data.data);
            }).catch(function (error) {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            });
        } else {
            setCompany(null);
        }

        fetchSurveys(0, tableData.rowsPerPage, '-lastRespondentAt', urlParamSearch);
        updateTitle(null);
        // eslint-disable-next-line
    }, [companyId]);

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);

        SurveyService.deleteByCombinedHash(deleteCombinedHash, true)
        .then(function (response) {
            setDeleteCombinedHash(null);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    const handleClose = () => {
        // Avoid multiple submits
        if (!closeDialogOpen) {
            return;
        }

        setCloseDialogOpen(false);
        updateLoading(true);
        setLoading(true);

        SurveyService.surveyCloseAdmin(closeCombinedHash, closeSkipWorkflow)
        .then(function (response) {
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            setCloseCombinedHash(null);
            updateLoading(false);
            setLoading(false);
            setCloseSkipWorkflow(false);
        });
    };

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title={( user && user.role === 'brcgs-admin' ? <Typography variant="body1"><FormattedMessage id="surveys.brcgsAdminSurveysTitle" defaultMessage="Below are shown the surveys registered by this account" /></Typography> :
                            ( company ? 'Surveys for: ' + company.attributes.name :
                                <Typography variant="body1">
                                    <FormattedMessage id="surveys.clickSurveyTitle" defaultMessage="Click the survey title below to view the report data."/>
                                </Typography>
                            )
                        )}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="sites.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>
                            This will delete ALL revisions of the survey as well as ALL associated response and benchmark data, continue?<br />
                            <br />
                            <strong>NOTE:</strong> the deletion process can take a few minutes, depending on the size of the survey.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={importMessagesDialogOpen}
                onClose={() => {setImportMessagesDialogOpen(false); setImportMessages([]);}}
            >
                <DialogTitle>Import Message(s)</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {importMessages.map(im => {
                            if (im.data.level === 'info') {
                                return <div key="info" className={classes.info}><strong>Info</strong>: {im.data.message}</div>
                            }
                            if (im.data.level === 'warning') {
                                return <div key="warning" className={classes.warning}><strong>Warning</strong>: {im.data.message}</div>
                            }
                            if (im.data.level === 'error') {
                                return <div key="error" className={classes.error}><strong>Error</strong>: {im.data.message}</div>
                            }
                            return null;
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setImportMessagesDialogOpen(false); setImportMessages([])}} color="primary" autoFocus>
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
            {user && user.role === 'brcgs-admin' ?
                <Fab className={classes.fab} component={RouterLink} to="/brcgs/register" color="secondary">
                    <AddIcon style={{ color: '#ffffff'}} />
                </Fab>
            : null}
            <Dialog
                open={brcgsRegisterNoticeOpen}
                onClose={() => {setBrcgsRegisterNoticeOpen(false); history.replace({ pathname: location.pathname, search: '' });}}
            >
                <DialogTitle><FormattedMessage id="surveys.brcgsNoticeTitle" defaultMessage="Thank You" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="surveys.brcgsNotice1" defaultMessage="The survey has been registered. It will appear on this screen shortly. You will also receive a confirmation email." />
                    </DialogContentText>
                    <DialogContentText>
                        <FormattedMessage id="surveys.brcgsNotice2" defaultMessage="Please ensure you check with your customer that they receive the welcome email, as they are occasionally filtered into spam/junk mailboxes." />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setBrcgsRegisterNoticeOpen(false); history.replace({ pathname: location.pathname, search: '' });}} color="primary">
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={closeDialogOpen}
                onClose={() => {setCloseDialogOpen(false);}}
            >
                <DialogTitle>Close Survey?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will close the survey.<br />
                        <br />
                        <strong>NOTE:</strong> The closure process can take some time, as it depends on the size of the download queue.
                    </DialogContentText>
                    <DialogContentText>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(event) => setCloseSkipWorkflow(!closeSkipWorkflow)}
                                value={closeSkipWorkflow} />}
                            label="Skip email workflow (close silently)"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setCloseDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleClose} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
