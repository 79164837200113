import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, TextField, Tooltip, makeStyles } from "@material-ui/core";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory, useParams, Link as RouterLink, Redirect } from "react-router-dom";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import SurveySheetService from "../../Services/SurveySheetService";
import { NotificationContext } from "../../Context/NotificationContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import CompanyService from "../../Services/CompanyService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import SurveyService from "../../Services/SurveyService";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    rowButton: {
        padding: '6px'
    },
    centeredNonSortHeader: {
        textAlign: 'center'
    },
    centeredCell: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    }
}));

export default function SurveySheets(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const historyRef = useRef();
    historyRef.current = () => history;
    const { uuid } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const urlParamSearch = searchParams.get('search') ? searchParams.get('search') : null;

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    };

    const [redirect, setRedirect] = useState(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [createFromSurvey, setCreateFromSurvey] = useState(null);
    const [createTitle, setCreateTitle] = useState('');
    const [companySurveys, setCompanySurveys] = useState(null);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [company, setCompany] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tableParams, setTableParams] = useState({
        page: 0,
        pageSize: 10,
        sort: 'title',
        search: urlParamSearch,
        refresh: 0
    });

    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {},
        data: [],
        searchText: '',
    });

    const hiddenOptions = {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false
    };

    const columns = [
        {
            name: 'id',
            options: hiddenOptions
        },
        {
            label: 'Title',
            name: 'attributes.title',
        },
        {
            label: 'Lead user',
            name: 'relationships.leadUser',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    if (value) {
                        return <span>{value.data.name} <em>({value.data.email})</em></span>
                    }
                    return null;
                }
            }
        },
        {
            label: 'Modules',
            name: 'attributes.modules',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    if (value) {
                        return value.join(', ');
                    }
                    return null;
                }
            }
        },
        {
            label: 'Languages',
            name: 'attributes.languages',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    if (value) {
                        return value.join(', ');
                    }
                    return '';
                }
            }
        },
        {
            label: 'Sites',
            name: 'attributes.sites',
            options: {
                sort: false,
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredNonSortHeader,
                }),
                customBodyRender: (value) => {
                    if (value && value.length) {
                        return value.length;
                    }
                    return '';
                }
            }
        },
        {
            label: 'Demographics',
            name: 'attributes.demographics',
            options: {
                sort: false,
                setCellProps: () => ({
                    className: classes.centeredCell,
                }),
                setCellHeaderProps: () => ({
                    className: classes.centeredNonSortHeader,
                }),
                customBodyRender: (value) => {
                    if (value && value.length) {
                        return value.length;
                    }
                    return '';
                }
            }
        },
        {
            name: 'attributes.createdAt',
            label: 'Created',
            options: {
                display: location.search === '',
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'attributes.updatedAt',
            label: 'Updated',
            options: {
                display: location.search === '',
                customBodyRender: (value) => {
                    if (value) {
                        // DD MMM YYYY, i.e. "01 Jan 2021"
                        return <span title={value}>{(new Date(value)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span>
                    }
                    return '';
                }
            }
        },
        {
            name: 'id',
            label: ' ',
            options: {
                sort: false,
                customBodyRender: (value) => (
                    <Fragment>
                        <Tooltip title="Print">
                            <IconButton className={classes.rowButton} component={RouterLink} target="_blank" to={'/companies/'+uuid+'/survey-sheets/'+value+'/print'}>
                                <PrintIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton className={classes.rowButton} component={RouterLink} to={'/companies/'+uuid+'/survey-sheets/'+value}>
                                <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton className={classes.rowButton} color="secondary" onClick={() => { setDeleteUuid(value); setDeleteDialogOpen(true);}}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Fragment>
                ),
                setCellProps: () => ({
                    align: 'right'
                }),
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : 'No data...',
            }
        },
        tableBodyHeight: 'auto',
        searchPlaceholder: '...',
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        searchText: urlParamSearch,
        customSearchRender: debounceSearchRender(500),
        filter: false,
        searchOpen: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100],
        onChangeRowsPerPage: (newPageSize) => setTableData(prevTableData => ({...prevTableData, rowsPerPage: newPageSize})),
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'title';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    setTableParams(previous => ({
                        ...previous,
                        page: tableState.page
                    }));
                    break;

                case 'sort':
                    setTableParams(previous => ({
                        ...previous,
                        sort: sortDir+sortCol
                    }));
                    break;

                case 'search':
                    setTableParams(previous => ({
                        ...previous,
                        search: tableState.searchText
                    }));
                    break;

                case 'changeRowsPerPage':
                    setTableParams(previous => ({
                        ...previous,
                        pageSize: tableState.rowsPerPage
                    }));
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const handleCreate = () => {
        // Create request, after redirect to edit
        setIsLoading(true);
        console.log(createFromSurvey);
        SurveySheetService.createSheet({title: createTitle}, {company: company}, createFromSurvey ? createFromSurvey.attributes.combinedHash : null)
        .then(response => {
            setRedirect('/companies/'+company.id+'/survey-sheets/'+response.data.data.id);
        }).catch(function (error) {
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            setIsLoading(false);
        });

        console.log('create');
    };

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        setIsLoading(true);

        SurveySheetService.delete(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setTableParams(previous => ({
                ...previous,
                refresh: tableParams.refresh+1
            }));
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, 'An unknown error occurred!', 'error');
                }
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
            console.error(error);
        }).then(function () {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        updateTitleRef.current(null);
        setIsLoading(true);
        CompanyService.company(uuid).then(function (response) {
            setCompany(response.data.data);
        }).catch(function (error) {
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            setIsLoading(false);
        });

        // Load surveys for company
        SurveyService.allSurveysByHash('title', null, {company: uuid}).then(response => {
            setCompanySurveys(response);
        }).catch(error => {
            console.log(error);
        });

    }, [uuid]);

    useEffect(() => {
        setIsLoading(true);
        const search = tableParams.search === '' ? null : tableParams.search;
        const queryParams = [];
        if (search !== null && search !== '') {
            queryParams.push('search='+search);
        }

        historyRef.current().push({search: queryParams.join('&')});
        console.log('FETCH#', tableParams.page, tableParams.pageSize, search);

        SurveySheetService.sheets(uuid, tableParams.page*tableParams.pageSize, tableParams.pageSize, tableParams.sort, search, {company: uuid})
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                rowsPerPage: tableParams.pageSize,
                searchText: search,
                page: tableParams.page
            });
        }).catch(function (error) {
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(function() {
            setIsLoading(false);
        });
    }, [uuid, tableParams.page, tableParams.pageSize, tableParams.sort, tableParams.search, tableParams.refresh]);

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12} style={{paddingBottom: '60px'}}>
                    <LocalBackdrop open={isLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title={company ? 'Survey sheets for: '+company.attributes.name : ''}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle>Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the survey sheet and all associated data, continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={createDialogOpen}
                onClose={() => {setCreateDialogOpen(false)}}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Create</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create new survey sheet.
                    </DialogContentText>
                    <Autocomplete
                        options={companySurveys ? companySurveys : []}
                        loading={companySurveys !== null}
                        value={createFromSurvey}
                        getOptionLabel={(option) => null !== option ? (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title) : 'All'}
                        onChange={(event, selected) => {
                            setCreateFromSurvey(selected);
                        }}
                        getOptionSelected={(option, value) => option && value ? option.id === value.id : option === value}
                        renderInput={(params) => (
                            <TextField {...params} margin="normal" label="From existing company survey" helperText="Leave empty to create a blank sheet" />
                        )}
                    />
                    <TextField
                        fullWidth
                        required
                        label="Survey title"
                        value={createTitle}
                        onChange={(event) => setCreateTitle(event.target.value)}
                        // helperText=""
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setCreateDialogOpen(false)}} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleCreate} color="secondary" disabled={createTitle === ''}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Fab className={classes.fab} onClick={() => setCreateDialogOpen(true)} color="secondary">
                <AddIcon />
            </Fab>
        </Fragment>
    );
}