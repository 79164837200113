import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom"
import CompanyService from "../../Services/CompanyService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SiteService from "../../Services/SiteService";
import UserService from "../../Services/UserService";
import SurveyService from "../../Services/SurveyService";
import SurveySheetService from "../../Services/SurveySheetService";
import AddIcon from '@material-ui/icons/Add';
import { Box, Button, MenuItem, Grid, Tab, Tabs, Table, TableCell, TableBody, TableHead, TableContainer, TableRow, TextField, Tooltip, Typography, makeStyles, IconButton, Select, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { COUNTRIES } from "../../Constants/Countries";
import { useIntl } from "react-intl";
import { typeTranslated } from "../Translation/Type";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PrintIcon from '@material-ui/icons/Print';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { DEMOGRAPHIC_TYPES } from "../../Constants/DemographicTypes";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { industries } from "../../Utilities/Industry";

const useStyles = makeStyles((theme) => ({
    new: {
        backgroundColor: 'rgba(146, 191, 32, 0.4)'
    },
    edited: {
        backgroundColor: 'rgba(146, 191, 32, 0.4)'
    },
    existing: {
        backgroundColor: 'rgba(27, 182, 214, 0.4)'
    },
    indicator: {
        backgroundColor: theme.palette.secondary.light
    },
    standard: {
        backgroundColor: 'rgba(223, 5, 65, 0.25)'
    },
    standardExisting: {
        backgroundColor: 'rgba(186, 85, 211, 0.4)'
    },
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    deleted: {
        backgroundColor: '#d9d9d9',
    }
}));


export default function SurveySheetEdit(props) {
    const intl = useIntl();
    const classes = useStyles();
    const { companyUuid, sheetUuid } = useParams();

    const [refresh, setRefresh] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [addDemographicType, setAddDemographicType] = useState('custom');

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    };

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (title) => {
        updateTitle(title);
    };

    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sites, setSites] = useState(null);
    const [users, setUsers] = useState(null);
    const [company, setCompany] = useState(null);
    const [sheet, setSheet] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [leadUserEmail, setLeadUserEmail] = useState('');
    const [leadUserName, setLeadUserName] = useState('');
    const [surveyTitle, setSurveyTitle] = useState('');
    const [addSiteToUsers, setAddSiteToUsers] = useState([]);
    const [siteBeingDragged, setSiteBeingDragged] = useState(null);
    const [siteBeingDraggedOver, setSiteBeingDraggedOver] = useState(null);
    const [siteDragBelow, setSiteDragBelow] = useState(true);
    const [siteGroups, setSiteGroups] = useState([]);

    const countries = [];
    for (let key in COUNTRIES) {
        countries.push({label: COUNTRIES[key], value: key});
    }

    useEffect(() => {
        if (null === sites || null === users || null === company || null === sheet || (sheet && sheet.attributes.createdFromSurveyCombinedHash && survey === null) || isLoading) {
            updateLoadingRef.current(true);
        } else {
            updateLoadingRef.current(false);
        }

        return () => {
            // Cleanup
            updateLoadingRef.current(false);
        };
    }, [sites, users, company, sheet, isLoading, survey]);

    useEffect(() => {
        updateTitleRef.current(null);
        CompanyService.company(companyUuid).then(response => {
            setCompany(response.data.data);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        });

        SiteService.allSites('name', null, {company: companyUuid}).then(response => {
            setSites(response);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        });

        UserService.allUsers('name', null, {company: companyUuid}).then(response => {
            setUsers(response);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        })

        SurveySheetService.sheet(sheetUuid).then(response => {
            setLeadUserEmail(response.data.data.attributes.leadUserEmail ? response.data.data.attributes.leadUserEmail : '');
            setLeadUserName(response.data.data.attributes.leadUserName ? response.data.data.attributes.leadUserName : '');
            setSurveyTitle(response.data.data.attributes.title ? response.data.data.attributes.title : '');
            setSheet(response.data.data);

            // Build site groups
            const siteGroupsToSet = [];
            (response.data.data.attributes.siteGroups ? response.data.data.attributes.siteGroups : ['Default']).forEach((sgName, sgIndex) => {
                const sg = {name: sgName, sites: [], expanded: true};
                (response.data.data.attributes.sites ? response.data.data.attributes.sites : []).forEach(s => {
                    if (s.group === sgIndex || (s.group === null || s.group === undefined)) {
                        sg.sites.push({...s});
                    }
                });
                siteGroupsToSet.push(sg);
            });
            setSiteGroups(siteGroupsToSet);

            if (response.data.data.attributes.createdFromSurveyCombinedHash) {
                SurveyService.surveyByHash(response.data.data.attributes.createdFromSurveyCombinedHash).then(response => {
                    setSurvey(response.data);
                });
            }
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        })

    }, [companyUuid, sheetUuid, refresh]);

    const classNameDemographicOption = (i, j) => {
        if (sheet.attributes.demographics[i].deleted) {
            return null;
        }

        if (sheet.attributes.demographics[i].deletedOptions && -1 !== sheet.attributes.demographics[i].deletedOptions.indexOf(sheet.attributes.demographics[i].options[j])) {
            return classes.deleted;
        }

        const alias = sheet.attributes.demographics[i].alias;
        const option = sheet.attributes.demographics[i].options[j];

        // Find out if this was used in survey this sheet is based on
        const previousQuestion = sheet.attributes.createdFromSurveyCombinedHash && survey && survey.included ? survey.included.find(q => q.attributes.demographic === alias) : null;

        const optionUsedInLastSurvey = previousQuestion && previousQuestion.attributes.options && previousQuestion.attributes.options.find(o => o.attributes.value === option);
        const optionStandard = alias in DEMOGRAPHIC_TYPES && -1 !== DEMOGRAPHIC_TYPES[alias].options.indexOf(option);

        if (optionUsedInLastSurvey && optionStandard) {
            return classes.standardExisting;
        } else if (optionStandard) {
            return classes.standard;
        } else if (optionUsedInLastSurvey) {
            return classes.existing;
        }

        return classes.new;
    }

    const classNameLeadUserEmail = () => {
        if (leadUserEmail && users && users.find(u => u.attributes.email.toLowerCase() === leadUserEmail.toLowerCase().trim())) {
            return classes.existing;
        } else if (leadUserEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classNameLeadUserName = () => {
        const foundUser = users ? users.find(u => u.attributes.email.toLowerCase() === leadUserEmail.toLowerCase()) : undefined;
        if (leadUserEmail && foundUser && leadUserName === foundUser.attributes.name) {
            return classes.existing;
        } else if (leadUserEmail && foundUser && leadUserName !== foundUser.attributes.name) {
            return classes.edited;
        } else if (leadUserEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classUserEmail = (index) => {
        const curUser = sheet.attributes.users[index];
        const foundUser = users && curUser && curUser.email ? users.find(u => u.attributes.email.toLowerCase() === curUser.email.toLowerCase().trim()) : undefined;

        if (foundUser) {
            return classes.existing;
        } else if (curUser && curUser.email && curUser.email.trim() !== '') {
            return classes.new;
        }
        return null;
    };

    const classUserName = (index) => {
        const curUser = sheet.attributes.users[index];
        const foundUser = users && curUser && curUser.name ? users.find(u => u.attributes.email.toLowerCase() === curUser.email.toLowerCase().trim()) : undefined;

        if (foundUser && foundUser.attributes.name === curUser.name) {
            return classes.existing;
        } else if (foundUser && curUser.name !== foundUser.attributes.name) {
            return classes.edited;
        } else if (curUser.name && curUser.name.trim() !== '') {
            return classes.new;
        }
        return null;
    };

    const classNameSiteLeadUserEmail = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundUser = users && curSite.siteContactEmail ? users.find(u => u.attributes.email.toLowerCase() === curSite.siteContactEmail.toLowerCase().trim()) : undefined;
        if (foundUser) {
            return classes.existing;
        } else if (curSite.siteContactEmail && curSite.siteContactEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classNameSiteLeadUserName = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundUser = users && curSite.siteContactEmail ? users.find(u => u.attributes.email.toLowerCase() === curSite.siteContactEmail.toLowerCase().trim()) : undefined;
        if (foundUser && curSite.siteContactName === foundUser.attributes.name) {
            return classes.existing;
        } else if (foundUser && curSite.siteContactName !== foundUser.attributes.name) {
            return classes.edited;
        } else if (curSite.siteContactName && curSite.siteContactName.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classSiteName = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite) {
            return classes.existing;
        } else if (sites) {
            return classes.new;
        }
        return null;
    };

    const classSiteIndustry = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && curSite.industry === foundSite.attributes.industry) {
            return classes.existing;
        } else if (foundSite && curSite.industry !== foundSite.attributes.industry) {
            return classes.edited;
        } else if (sites && curSite.industry) {
            return classes.new;
        }
        return null;
    };

    const classSiteCountry = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && curSite.countryCode === foundSite.attributes.countryCode) {
            return classes.existing;
        } else if (foundSite && curSite.countryCode !== foundSite.attributes.countryCode) {
            return classes.edited;
        } else if (sites && curSite.countryCode) {
            return classes.new;
        }
        return null;
    };

    const classSiteTarget = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && parseInt(curSite.totalTarget) === foundSite.attributes.totalTarget) {
            return classes.existing;
        } else if (foundSite && curSite.totalTarget !== foundSite.attributes.totalTarget) {
            return classes.edited;
        } else if (sites && curSite.totalTarget) {
            return classes.new;
        }
        return null;
    };

    const handleUserChange = (index, field) => event => {
        const currentUsers = sheet.attributes.users;

        currentUsers[index][field] = event.target.value;

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                users: currentUsers
            }
        }));
    };

    const handleAccordionExpand = (index) => (event, isExpanded) => {
        const currentSiteGroups = [...siteGroups];
        currentSiteGroups[index].expanded = isExpanded;
        setSiteGroups(currentSiteGroups);
    };
    const handleSiteGroupNameChange = (index) => event => {
        event.stopPropagation();
        const currentSiteGroups = [...siteGroups];
        currentSiteGroups[index].name = event.target.value;
        setSiteGroups(currentSiteGroups);
    };

    const handleSiteChange = (sgIndex, siteIndex, field) => event => {
        const currentSiteGroups = [...siteGroups];
        if (field === 'commentsAccess') {
            currentSiteGroups[sgIndex].sites[siteIndex][field] = event.target.checked;
        } else {
            currentSiteGroups[sgIndex].sites[siteIndex][field] = event.target.value;
        }

        setSiteGroups(currentSiteGroups);
    };

    const handleRestoreSite = (sgIndex, siteIndex) => () => {
        const currentSiteGroups = [...siteGroups];
        currentSiteGroups[sgIndex].sites[siteIndex].deleted = false;
        setSiteGroups(currentSiteGroups);
    };
    const handleSetAddSiteToUser = (i) => event => {
        const addSiteToUsersCopy = [...addSiteToUsers];
        addSiteToUsersCopy[i] = event.target.value
        setAddSiteToUsers(addSiteToUsersCopy);
    };

    const handleAddSiteToUser = (i) => () => {
        if (addSiteToUsers && addSiteToUsers[i] && sheet.attributes.users[i].sites && -1 === sheet.attributes.users[i].sites.indexOf(addSiteToUsers[i])) {
            const currentUsers = sheet.attributes.users;
            if (addSiteToUsers[i] !== 'all') {
                currentUsers[i].sites = currentUsers[i].sites.filter(s => s !== 'all');
                currentUsers[i].sites.push(addSiteToUsers[i]);
            } else {
                currentUsers[i].sites = ['all'];
            }

            setSheet(prevSheet => ({
                ...prevSheet,
                attributes: {
                    ...prevSheet.attributes,
                    users: currentUsers
                }
            }));
        }
    };

    const handleUserDelete = (i) => () => {
        const currentUsers = sheet.attributes.users;
        currentUsers.splice(i, 1);

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                users: currentUsers
            }
        }));

        if (i < addSiteToUsers.length) {
            const addSiteToUsersCopy = [...addSiteToUsers];
            addSiteToUsersCopy.splice(i, 1);
            setAddSiteToUsers(addSiteToUsersCopy);
        }
    };

    const handleUserDeleteSite = (i, j) => () => {
        const currentUsers = sheet.attributes.users;
        currentUsers[i].sites.splice(j, 1);

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                users: currentUsers
            }
        }));
    };

    const handleSiteDelete = (sgIndex, siteIndex) => () => {
        const currentSiteGroups = [...siteGroups];

        if (currentSiteGroups[sgIndex].sites[siteIndex].deleted) {
            currentSiteGroups[sgIndex].sites.splice(siteIndex, 1);
        } else {
            currentSiteGroups[sgIndex].sites[siteIndex].deleted = true;
        }

        setSiteGroups(currentSiteGroups);
    };

    const handleNewDemographic = () => {
        const demographics = sheet.attributes.demographics;
        demographics.push({
            alias: addDemographicType,
            title: DEMOGRAPHIC_TYPES[addDemographicType].title,
            options: [...DEMOGRAPHIC_TYPES[addDemographicType].options],
            deletedOptions: [],
            anonymityInclude: true,
            deleted: false
        });

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics
            }
        }));
    };

    const handleRestoreDemographic = (i) => () => {
        const demographics = sheet.attributes.demographics;
        demographics[i].deleted = false;

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics,
            }
        }));
    };

    const handleDemographicDelete = (i) => () => {
        const demographics = sheet.attributes.demographics;
        const currentSiteGroups = [...siteGroups];

        if (demographics[i].deleted) {
            currentSiteGroups.forEach((sg, sgIndex) => {
                currentSiteGroups[sgIndex].sites.forEach((site, siteIndex) => {
                    if (site.demographics && sheet.attributes.demographics[i].alias in site.demographics) {
                        delete currentSiteGroups[sgIndex].sites[siteIndex].demographics[sheet.attributes.demographics[i].alias];
                    }
                });
            });
            demographics.splice(i, 1);
        } else {
            demographics[i].deleted = true;
        }

        setSiteGroups(currentSiteGroups);
        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics,
            }
        }));
    };

    const handleRestoreDemographicOption = (i, j) => () => {
        const demographics = sheet.attributes.demographics;
        const deletedOptionIndex = demographics[i].deletedOptions.indexOf(demographics[i].options[j]);
        if (-1 !== deletedOptionIndex) {
            demographics[i].deletedOptions.splice(deletedOptionIndex, 1);
        }

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics
            }
        }));
    };

    const handleDemographicOptionDelete = (i, j) => () => {
        const demographics = sheet.attributes.demographics;

        if (!demographics[i].deletedOptions) {
            demographics[i].deletedOptions = [];
        }

        const deletedOptionIndex = demographics[i].deletedOptions.indexOf(demographics[i].options[j]);

        if (-1 === deletedOptionIndex) {
            demographics[i].deletedOptions.push(demographics[i].options[j]);
        } else {
            demographics[i].options.splice(j, 1);
            demographics[i].deletedOptions.splice(deletedOptionIndex, 1);
        }

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics
            }
        }));
    };

    const handleDemographicOptionNew = (i) => () => {
        const demographics = sheet.attributes.demographics;
        demographics[i].options.push('');

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics
            }
        }));
    };

    const handleDemographicOptionChange = (i, j) => event => {
        const demographics = sheet.attributes.demographics;
        demographics[i].options[j] = event.target.value;

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics
            }
        }));
    };

    const handleSiteDragStart = (sgIndex, siteIndex) => event => {
        setSiteBeingDragged({sg: sgIndex, site: siteIndex});
    };

    const handleSiteDragEnd = (sgIndex, siteIndex) => event => {
        if (siteBeingDraggedOver !== null && siteBeingDragged !== null && (
                // Only move site if needed
                siteBeingDragged.sg !== siteBeingDraggedOver.sg ||
                (siteBeingDragged.site !== siteBeingDraggedOver.site)
            ) && (
                // Ignore if we're dragging below the one above or above the one below the current site being dragged (if in same group)
                (siteBeingDragged.sg !== siteBeingDraggedOver.sg) ||
                (!siteDragBelow && siteBeingDragged.site+1 !== siteBeingDraggedOver.site) ||
                (siteDragBelow && siteBeingDragged.site-1 !== siteBeingDraggedOver.site)
            )
        ) {
            const currentSiteGroups = [...siteGroups];
            const siteToMove = currentSiteGroups[sgIndex].sites[siteIndex];

            // If different group, delete from origin add to target
            if (sgIndex !== siteBeingDraggedOver.sg) {
                let targetIndex = siteBeingDraggedOver.site;
                if (siteDragBelow) {
                    targetIndex++;
                }
                currentSiteGroups[sgIndex].sites.splice(siteIndex, 1);
                currentSiteGroups[siteBeingDraggedOver.sg].sites.splice(targetIndex, 0, siteToMove);

            } else { // If same group, move site
                let targetIndex = siteBeingDraggedOver.site;

                if (siteDragBelow && targetIndex !== currentSiteGroups[sgIndex].sites.length-1 && targetIndex < siteIndex) {
                    targetIndex++;
                }
                currentSiteGroups[sgIndex].sites.splice(siteIndex, 1);
                currentSiteGroups[sgIndex].sites.splice(targetIndex, 0, siteToMove);
            }

            setSiteGroups(currentSiteGroups);
        }
        setSiteBeingDragged(null);
        setSiteBeingDraggedOver(null);
    };

    const handleSiteDragOverEmptyGroup = (sgIndex) => event => {
        if (siteGroups[sgIndex].sites.length === 0 && // Grp has to have 0 sites
            (
                siteBeingDraggedOver === null || // And siteBeingDraggedOver either not set
                siteBeingDraggedOver.sg !== sgIndex || // Or set to a different grp
                siteBeingDraggedOver.site !== null // Or set to a different site
            )) {
            setSiteBeingDraggedOver({sg: sgIndex, site: null});
        }
    };

    const handleSiteDragOver = (sgIndex, siteIndex) => event => {
        if (!siteBeingDragged || siteBeingDragged.sg !== sgIndex || siteBeingDragged.site !== siteIndex) {

            if (!siteBeingDraggedOver || siteBeingDraggedOver.sg !== sgIndex || siteBeingDraggedOver.site !== siteIndex) {
                setSiteBeingDraggedOver({sg: sgIndex, site: siteIndex});
            }

            const bounding = event.target.getBoundingClientRect();
            const offset = bounding.y + (bounding.height/2);

            let dragBelow = false;
            if (event.clientY - offset > 0) {
                dragBelow = true;
            }

            if (dragBelow !== siteDragBelow) {
                setSiteDragBelow(dragBelow);
            }
        }
        event.stopPropagation();
        event.preventDefault();
    };

    const moveSiteGroup = (index, up = false) => {
        const currentSiteGroups = [...siteGroups];
        const moveTo = up ? index-1 : index+1;

        [currentSiteGroups[index], currentSiteGroups[moveTo]] = [currentSiteGroups[moveTo], currentSiteGroups[index]]
        setSiteGroups(currentSiteGroups);
    };

    const handleDemographicsChange = (i, field) => event => {
        // Check if alias we are trying to set already exists in another demographic
        const aliasIsNotUnique = field === 'alias' && sheet.attributes.demographics.some((dem, index) => (
            index !== i && dem.alias === event.target.value
        ));

        if (aliasIsNotUnique) {
            updateNotification(true, 'You cannot use the same alias more than once', 'warning');
            return;
        }
        // if (field === 'anonymityInclude') {
        // }

        // Cleanup site demographics in case of changed alias
        // let sheetSites = sheet.attributes.sites;
        // if ('alias' === field) {
        //     const sheetSites = sheet.attributes.sites.map(s => {
        //         if (sheet.attributes.demographics[i].alias in s.demographics) {
        //             delete s.demographics[sheet.attributes.demographics[i].alias];
        //         }
        //         return s;
        //     });
        // }

        const demographics = sheet.attributes.demographics;

        const newValue = field === 'anonymityInclude' ? event.target.checked : event.target.value;
        demographics[i][field] = newValue;

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                demographics: demographics,
                // sites: sheetSites
            }
        }));
    };

    const handleNewUser = () => {
        const currentUsers = sheet.attributes.users ? sheet.attributes.users : [];

        currentUsers.push({
            name: '',
            email: '',
            sites: []
        });

        setSheet(prevSheet => ({
            ...prevSheet,
            attributes: {
                ...prevSheet.attributes,
                users: currentUsers
            }
        }));
    };

    const handleNewSiteGroup = () => {
        setSiteGroups(prevSiteGroups => ([...prevSiteGroups, {name: 'New group...', sites:[]}]));
    };

    const handleNewSite = (sgIndex) => {
        const currentSiteGroups = [...siteGroups];

        currentSiteGroups[sgIndex].sites.push({
            name: 'New site...',
            totalTarget: null,
            industry: null,
            countryCode: null,
            siteContactEmail: null,
            siteContactName: null,
            deleted: false,
            group: sgIndex
        });
        setSiteGroups(currentSiteGroups);
    };

    const handleSiteDemographic = (sgIndex, siteIndex, demographicAlias) => (event) => {
        const currentSiteGroups = [...siteGroups];

        if (!currentSiteGroups[sgIndex].sites[siteIndex].demographics) {
            currentSiteGroups[sgIndex].sites[siteIndex].demographics = {};
        }
        currentSiteGroups[sgIndex].sites[siteIndex].demographics[demographicAlias] = event.target.value;
        setSiteGroups(currentSiteGroups);
    };

    const handleSave = () => {
        setIsLoading(true);
        const sitesToSave = [];
        const siteGroupsToSave = [];
        siteGroups.forEach((sg, sgIndex) => {
            siteGroupsToSave.push(sg.name);
            sg.sites.forEach(s => {
                sitesToSave.push({
                    countryCode: s.countryCode ? s.countryCode : null,
                    industry: s.industry ? s.industry : null,
                    name: s.name && s.name.trim() ? s.name.trim() : null,
                    siteContactEmail: s.siteContactEmail && s.siteContactEmail.trim() ? s.siteContactEmail.trim() : null,
                    siteContactName: s.siteContactName && s.siteContactName.trim() ? s.siteContactName.trim() : null,
                    totalTarget: s.totalTarget ? parseInt(s.totalTarget) : null,
                    demographics: s.demographics && Object.keys(s.demographics).length ? s.demographics : null,
                    deleted: s.deleted ? true : false,
                    commentsAccess: s.commentsAccess && s.commentsAccess === true ? true : false,
                    group: sgIndex
                });
            })
        });
        const attributes = {
            title: sheet.attributes.title,
            demographics: sheet.attributes.demographics,
            sites: sitesToSave,
            users: sheet.attributes.users && sheet.attributes.users.length ? sheet.attributes.users : null,
            languages: sheet.attributes.languages,
            modules: sheet.attributes.modules,
            leadUserEmail: leadUserEmail && leadUserEmail.trim() ? leadUserEmail.trim() : null,
            leadUserName: leadUserName && leadUserName.trim() ? leadUserName.trim() : null,
            siteGroups: siteGroupsToSave,
        };

        // Remove any demographics in sites that do not exist in overall demographics (cleanup)
        attributes.sites.forEach(s => {
            if (s.demographics && Object.keys(s.demographics).length) {
                const aliases = sheet.attributes.demographics.map(d => d.alias);
                s.demographics = Object.fromEntries(aliases.map(a => [a, s.demographics[a]]));
            }
        });

        SurveySheetService.patch(sheet.id, attributes).then(response => {
            updateNotification(true, 'Sheet saved', 'success');
        }).catch(error => {
            console.error(error);
            if (400 === error.response.status) {
                updateNotification(true, error.response.data.error.detail, 'error')
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
        }).then(() => {
            setIsLoading(false);
        })
    }

    const handleSubmit = () => {
        setSubmitDialogOpen(false);
        // Save first
        setIsLoading(true);
        const sitesToSave = [];
        const siteGroupsToSave = [];
        siteGroups.forEach((sg, sgIndex) => {
            siteGroupsToSave.push(sg.name);
            sg.sites.forEach(s => {
                sitesToSave.push({
                    countryCode: s.countryCode ? s.countryCode : null,
                    industry: s.industry ? s.industry : null,
                    name: s.name && s.name.trim() ? s.name.trim() : null,
                    siteContactEmail: s.siteContactEmail && s.siteContactEmail.trim() ? s.siteContactEmail.trim() : null,
                    siteContactName: s.siteContactName && s.siteContactName.trim() ? s.siteContactName.trim() : null,
                    totalTarget: s.totalTarget ? parseInt(s.totalTarget) : null,
                    demographics: s.demographics && Object.keys(s.demographics).length ? s.demographics : null,
                    deleted: s.deleted ? true : false,
                    commentsAccess: s.commentsAccess && s.commentsAccess === true ? true : false,
                    group: sgIndex
                });
            })
        });
        const attributes = {
            title: sheet.attributes.title,
            demographics: sheet.attributes.demographics,
            sites: sitesToSave,
            users: sheet.attributes.users && sheet.attributes.users.length ? sheet.attributes.users : null,
            languages: sheet.attributes.languages,
            modules: sheet.attributes.modules,
            leadUserEmail: leadUserEmail && leadUserEmail.trim() ? leadUserEmail.trim() : null,
            leadUserName: leadUserName && leadUserName.trim() ? leadUserName.trim() : null,
            siteGroups: siteGroupsToSave,
        };
        SurveySheetService.patch(sheet.id, attributes).then(response => {
            // Then apply all changes
            SurveySheetService.apply(sheet.id).then(response => {
                updateNotification(true, 'Sheet submitted', 'success');
                setRefresh(refresh+1);
            }).catch(error => {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }).then(() => {
                setIsLoading(false);
            });

        }).catch(error => {
            console.error(error);
            if (400 === error.response.status) {
                updateNotification(true, error.response.data.error.detail, 'error')
            } else {
                updateNotification(true, 'An unknown error occurred!', 'error');
            }
        });
    }

    const tabContent = () => {
        const anonymityLevel = (sgIndex, siteIndex) => {
            if (siteGroups[sgIndex].sites[siteIndex].totalTarget && parseInt(siteGroups[sgIndex].sites[siteIndex].totalTarget) && sheet.attributes.demographics && sheet.attributes.demographics.length) {
                return sheet.attributes.demographics.reduce((acc, cur) => {
                    if (cur.anonymityInclude && !cur.deleted && cur.options && cur.options.length) {
                        // Only count options that are not disabled (if all are disabled, length = 1)
                        const deletedCount = cur.deletedOptions && cur.deletedOptions.length ? cur.deletedOptions.length : 0;
                        if (deletedCount === cur.options.length || deletedCount > cur.options.length) {
                            return acc;
                        }
                        return acc * (cur.options.length - deletedCount);
                    }
                    return acc;
                }, 5) <= parseInt(siteGroups[sgIndex].sites[siteIndex].totalTarget) ? '🦄' : '🐢';
            }
            return null;
        };

        if (0 === activeTab) {
            // Sites
            return (<Fragment>
                {(siteGroups ? siteGroups : []).map((sg, sgIndex) =>
                    <Accordion key={sgIndex} defaultExpanded onChange={handleAccordionExpand(sgIndex)} expanded={sg.expanded === undefined || sg.expanded === true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <TextField
                                onClick={(event) => event.stopPropagation()}
                                onChange={handleSiteGroupNameChange(sgIndex)}
                                value={siteGroups[sgIndex].name}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {sg && sg.sites ?
                                    <Grid item xs={12}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow onDragOver={handleSiteDragOverEmptyGroup(sgIndex)} style={siteBeingDraggedOver && siteBeingDraggedOver.sg === sgIndex && sg.sites.length === 0 ? {borderBottom: '2px solid #2196f3'} : null}>
                                                        <TableCell style={{paddingLeft: 0, paddingRight: 0}} />
                                                        <TableCell style={{width: '25%'}}>Site</TableCell>
                                                        <TableCell>Industry</TableCell>
                                                        <TableCell style={{width: '12%'}}>Country</TableCell>
                                                        <TableCell align="right">Total Employees (approx.)</TableCell>
                                                        <TableCell align="center">Anonymity Level</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="How many multi-site users will get access to this site (if any)"><span>MSU</span></Tooltip>
                                                        </TableCell>
                                                        <TableCell>Site Contact Name</TableCell>
                                                        <TableCell>Site Contact Email</TableCell>
                                                        <TableCell align="center">Comments Access</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {siteGroups[sgIndex].sites.map((site, i) => {
                                                        const msuForSite = sheet.attributes.users ? sheet.attributes.users.filter(u => u.sites && (u.sites.includes(site.name) || (u.sites.length === 1 && u.sites[0] === 'all'))) : [];
                                                        const rowStyle = {};
                                                        if (siteBeingDraggedOver && siteBeingDraggedOver.sg === sgIndex && siteBeingDraggedOver.site === i) {
                                                            if (siteDragBelow) {
                                                                rowStyle['borderBottom'] = '2px solid #2196f3';
                                                            } else {
                                                                rowStyle['borderTop'] = '2px solid #2196f3';
                                                            }
                                                        }

                                                        if (siteBeingDragged && siteBeingDragged.sg === sgIndex && siteBeingDragged.site === i) {
                                                            rowStyle['backgroundColor'] = '#ccc';
                                                        }

                                                        return (
                                                            <TableRow
                                                                key={i}
                                                                className={site.deleted ? classes.deleted : null}
                                                                draggable={true}
                                                                onDragStart={handleSiteDragStart(sgIndex, i)}
                                                                onDragEnd={handleSiteDragEnd(sgIndex, i)}
                                                                onDragOver={handleSiteDragOver(sgIndex, i)}
                                                                style={rowStyle}
                                                            >
                                                                <TableCell style={{minWidth: 50, paddingLeft: 0, paddingRight: 0}}>
                                                                    <DragHandleIcon style={{cursor: 'grab'}} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        fullWidth
                                                                        onChange={handleSiteChange(sgIndex, i, 'name')}
                                                                        value={siteGroups[sgIndex].sites[i].name}
                                                                        className={classSiteName(sgIndex, i)}
                                                                        disabled={site.deleted}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        fullWidth
                                                                        select
                                                                        disabled={site.deleted}
                                                                        value={siteGroups[sgIndex].sites[i].industry ? siteGroups[sgIndex].sites[i].industry : ''}
                                                                        onChange={handleSiteChange(sgIndex, i, 'industry')}
                                                                        SelectProps={{
                                                                            native: true,
                                                                        }}
                                                                        className={classSiteIndustry(sgIndex, i)}
                                                                    >
                                                                        <option value="" disabled></option>
                                                                        {industries().map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </TextField>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        fullWidth
                                                                        select
                                                                        disabled={site.deleted}
                                                                        value={siteGroups[sgIndex].sites[i].countryCode ? siteGroups[sgIndex].sites[i].countryCode : ''}
                                                                        onChange={handleSiteChange(sgIndex, i, 'countryCode')}
                                                                        SelectProps={{
                                                                            native: true,
                                                                        }}
                                                                        className={classSiteCountry(sgIndex, i)}
                                                                    >
                                                                        <option value=""></option>
                                                                        {countries.map(option => (
                                                                            <option key={option.value} value={option.value} >
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </TextField>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        fullWidth
                                                                        disabled={site.deleted}
                                                                        onChange={handleSiteChange(sgIndex, i, 'totalTarget')}
                                                                        value={siteGroups[sgIndex].sites[i].totalTarget ? siteGroups[sgIndex].sites[i].totalTarget : ''}
                                                                        onWheel={event => event.target.blur()} // Avoid changing when mouse is hovering on field and scroll wheel is used
                                                                        type="number"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                style: {textAlign: 'end'},
                                                                                step: 1,
                                                                                min: 0,
                                                                                pattern: "[0-9]+"
                                                                            }
                                                                        }}
                                                                        className={classSiteTarget(sgIndex, i)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {anonymityLevel(sgIndex, i)}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {msuForSite.length ?
                                                                        <Tooltip title={
                                                                            <span>
                                                                                {msuForSite.map((u, i) => <span key={i}>{u.name} - {u.email}<br/></span>)}
                                                                            </span>
                                                                        }>
                                                                            <span>{msuForSite.length}</span>
                                                                        </Tooltip>
                                                                    : null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        fullWidth
                                                                        disabled={site.deleted}
                                                                        onChange={handleSiteChange(sgIndex, i, 'siteContactName')}
                                                                        value={siteGroups[sgIndex].sites[i].siteContactName ? siteGroups[sgIndex].sites[i].siteContactName : ''}
                                                                        className={classNameSiteLeadUserName(sgIndex, i)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        draggable={true}
                                                                        onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                                        type="email"
                                                                        fullWidth
                                                                        disabled={site.deleted}
                                                                        onChange={handleSiteChange(sgIndex, i, 'siteContactEmail')}
                                                                        value={siteGroups[sgIndex].sites[i].siteContactEmail ? siteGroups[sgIndex].sites[i].siteContactEmail : ''}
                                                                        className={classNameSiteLeadUserEmail(sgIndex, i)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center" draggable={true} onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}>
                                                                    <Checkbox
                                                                        checked={siteGroups[sgIndex].sites[i].commentsAccess ? true : false}
                                                                        onChange={handleSiteChange(sgIndex, i, 'commentsAccess')} />
                                                                </TableCell>
                                                                <TableCell align="left" draggable={true} onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}>
                                                                    <Tooltip title={site.deleted ? 'Delete site' : 'Mark for deletion'}>
                                                                        <IconButton onClick={handleSiteDelete(sgIndex, i)} color="secondary">
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {site.deleted ?
                                                                        <Tooltip title="Restore">
                                                                            <IconButton onClick={handleRestoreSite(sgIndex, i)} color="primary">
                                                                                <SettingsBackupRestoreIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                : null}
                                <Grid item xs={12}>
                                    <hr />
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <Tooltip title="Add site" placement="right" color="secondary">
                                            <IconButton size="small" onClick={() => handleNewSite(sgIndex)}>
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        {sheet ?
                                            <Fragment>
                                                <Tooltip title="Move site group up">
                                                    <ArrowUpwardIcon style={{cursor: (sgIndex === 0 ? 'auto' : 'pointer'), color: (sgIndex === 0 ? '#ccc' : '#000000')}} onClick={() => sgIndex === 0 ? {} : moveSiteGroup(sgIndex, true)} />
                                                </Tooltip>
                                                <Tooltip title="Move site group down">
                                                    <ArrowDownwardIcon style={{cursor: (sgIndex === siteGroups.length-1 ? 'auto' : 'pointer'), color: (sgIndex === siteGroups.length-1 ? '#ccc' : '#000000')}} onClick={() => sgIndex === siteGroups.length-1 ? {} : moveSiteGroup(sgIndex, false)} />
                                                </Tooltip>
                                            </Fragment>
                                        : null}
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
                <Tooltip title="Add site group" placement="right" color="secondary">
                    <IconButton size="small" onClick={() => handleNewSiteGroup()}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Fragment>);
        } else if (1 === activeTab) {
            // Multi-site users
            return (
                <Fragment>
                    {sheet && sheet.attributes.users ?
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '30%' }}>Name</TableCell>
                                        <TableCell style={{ width: '30%' }}>Email</TableCell>
                                        <TableCell style={{ width: '30%' }}>Sites</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sheet.attributes.users.map((currentUser, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleUserChange(i, 'name')}
                                                    value={sheet.attributes.users[i].name ? sheet.attributes.users[i].name : ''}
                                                    className={classUserName(i)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    onChange={handleUserChange(i, 'email')}
                                                    value={sheet.attributes.users[i].email ? sheet.attributes.users[i].email : ''}
                                                    className={classUserEmail(i)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ul>
                                                    {currentUser.sites.map((currentUserSite, j) => {
                                                        const siteFound = currentUserSite === 'all' || siteGroups.some(sg => (sg.sites.some(s => s.name === currentUserSite)));
                                                        return (
                                                            <li key={j}>
                                                                <Fragment>
                                                                    {!siteFound ?
                                                                        <Tooltip title="Site not found in sites tab"><span style={{ color: 'red' }}>{currentUserSite}</span></Tooltip>
                                                                    : currentUserSite !== 'all' ? currentUserSite : 'All'}
                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={handleUserDeleteSite(i, j)} color="secondary" size="small">
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Fragment>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                <Select value={addSiteToUsers[i] ? addSiteToUsers[i] : ''} onChange={handleSetAddSiteToUser(i)} style={{ width: '90%' }}>
                                                    <MenuItem value={'all'}>All</MenuItem>
                                                    {siteGroups.map((sg, sgIndex) => (
                                                        sg.sites.map((s, k) => (
                                                            <MenuItem key={k} value={s.name}>
                                                                {s.name}
                                                            </MenuItem>
                                                        ))
                                                    ))}
                                                </Select>
                                                <IconButton size="small" onClick={handleAddSiteToUser(i)}>
                                                    <AddIcon color="secondary" fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={handleUserDelete(i)} color="secondary">
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : null}
                    <Tooltip title="Add user" placement="right" color="secondary">
                        <IconButton size="small" onClick={handleNewUser}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Fragment>
            );
        } else if (2 === activeTab) {
            // Demographics
            return (
                <Fragment>
                    {sheet && sheet.attributes.demographics ?
                        sheet.attributes.demographics.map((dem, i) => (
                            <Box key={i} py={1} style={{borderBottom: '1px solid #ccc'}}>
                                <Grid container spacing={0} justifyContent="center" className={dem.deleted ? classes.deleted : null}>
                                    <Grid item xs={12} align="right">
                                        <Tooltip title="Delete demographic">
                                            <IconButton onClick={handleDemographicDelete(i)} color="secondary">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        {dem.deleted ?
                                            <Tooltip title="Restore">
                                                <IconButton onClick={handleRestoreDemographic(i)} color="primary">
                                                    <SettingsBackupRestoreIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        : null}
                                    </Grid>
                                    <Grid item xs={5} style={{paddingRight: 10}}>
                                        <TextField
                                            required
                                            fullWidth
                                            disabled={dem.deleted}
                                            label="Title"
                                            value={sheet.attributes.demographics[i].title}
                                            onChange={handleDemographicsChange(i, 'title')}
                                            margin="normal" />
                                    </Grid>
                                    <Grid item xs={5} style={{paddingLeft: 10}}>
                                        <TextField
                                            required
                                            fullWidth
                                            disabled={dem.deleted}
                                            label="Alias"
                                            value={sheet.attributes.demographics[i].alias}
                                            onChange={handleDemographicsChange(i, 'alias')}
                                            margin="normal"
                                            helperText={<span><strong>NOTE:</strong> Changing this will clear any values related to the demographic in the <em>Sites & Demographics</em> tab</span>} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            style={{paddingTop: 15}}
                                            disabled={dem.deleted}
                                            control={
                                                <Checkbox
                                                    checked={sheet.attributes.demographics[i].anonymityInclude ? true : false}
                                                    onChange={handleDemographicsChange(i, 'anonymityInclude')}
                                                />
                                            }
                                            label="Use in anonymity calculation"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">Options</Typography>
                                        {dem.options ? dem.options.map((opt, j) => (
                                            <div key={j} style={{paddingLeft: 30}}>
                                                <TextField
                                                    style={{width: '33%'}}
                                                    required
                                                    disabled={dem.deleted || (dem.deletedOptions && -1 !== dem.deletedOptions.indexOf(sheet.attributes.demographics[i].options[j]))}
                                                    label=""
                                                    value={sheet.attributes.demographics[i].options[j]}
                                                    onChange={handleDemographicOptionChange(i, j)}
                                                    margin="dense"
                                                    className={classNameDemographicOption(i, j)}
                                                />
                                                <Tooltip title="Delete option">
                                                    <IconButton disabled={dem.deleted} onClick={handleDemographicOptionDelete(i, j)} color="secondary">
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {(dem.deletedOptions && -1 !== dem.deletedOptions.indexOf(sheet.attributes.demographics[i].options[j])) ?
                                                    <Tooltip title="Restore">
                                                        <IconButton onClick={handleRestoreDemographicOption(i, j)} color="primary">
                                                            <SettingsBackupRestoreIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                : null}
                                            </div>
                                        )) : null}
                                        <Tooltip title="Add option" placement="right">
                                            <IconButton color="secondary" onClick={handleDemographicOptionNew(i)}><AddIcon fontSize="small" /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                    : null}
                    <Box py={2}>
                        <Select value={addDemographicType} onChange={event => setAddDemographicType(event.target.value)}>
                            {Object.keys(DEMOGRAPHIC_TYPES).map(dt => (
                                <MenuItem key={dt} value={dt} disabled={sheet.attributes.demographics.some((d => d.alias === dt))}>{DEMOGRAPHIC_TYPES[dt].title}</MenuItem>
                            ))}
                        </Select>
                        <Tooltip title="Add demographic" placement="right">
                            <IconButton color="secondary" onClick={handleNewDemographic}><AddIcon fontSize="small" /></IconButton>
                        </Tooltip>
                    </Box>
                </Fragment>
            );
        } else if (3 === activeTab) {
            // Sites & Demographics
            return (
                <Fragment>
                    {sheet && sheet.attributes.demographics && siteGroups ?
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Site</TableCell>
                                        <TableCell align="center" style={{width: '7%'}}>Anonymity Level</TableCell>
                                        {sheet.attributes.demographics.map((dem, l) => (
                                            <TableCell key={l}>{dem.title}</TableCell>
                                        ))}
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {siteGroups.map((sg, sgIndex) => (
                                        siteGroups[sgIndex].sites.map((site, siteIndex) => (
                                            <TableRow key={sgIndex+'-'+siteIndex} className={site.deleted ? classes.deleted : null}>
                                                <TableCell>{site.name}</TableCell>
                                                <TableCell align="center">{anonymityLevel(sgIndex, siteIndex)}</TableCell>
                                                {sheet.attributes.demographics.map((dem, l) => (
                                                    <TableCell key={l} className={dem.deleted ? classes.deleted : null}>
                                                        <TextField
                                                            label=""
                                                            disabled={dem.deleted || site.deleted}
                                                            fullWidth
                                                            value={siteGroups[sgIndex].sites[siteIndex].demographics && dem.alias in siteGroups[sgIndex].sites[siteIndex].demographics ? siteGroups[sgIndex].sites[siteIndex].demographics[dem.alias] : ''}
                                                            onChange={handleSiteDemographic(sgIndex, siteIndex, dem.alias)}
                                                            margin="dense"
                                                        />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    : null}
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={6} xs={12}>
                    <Box pr={2}>
                        <Typography variant="h6">Survey Settings</Typography>
                        <TextField
                            fullWidth
                            required
                            label="Survey title"
                            value={surveyTitle}
                            onChange={event => setSurveyTitle(event.target.value)}
                            margin="normal" />
                        <TextField
                            fullWidth
                            disabled
                            required
                            label="Company"
                            value={company ? company.attributes.name : ''}
                            margin="normal"
                            helperText={<span>Company details can be changed <a href={"/companies/"+companyUuid} rel="noreferrer" target="_blank">here</a></span>} />
                        <Autocomplete
                            freeSolo
                            options={users ? users : []}
                            value={leadUserEmail}
                            getOptionLabel={(option) => typeof option === 'object' && option !== null ? option.attributes.email : option}
                            onInputChange={(event, newValue) => {
                                const foundUser = users ? users.find(u => u.attributes.email.toLowerCase() === newValue.toLocaleLowerCase()) : null;
                                if (foundUser) {
                                    setLeadUserEmail(foundUser.attributes.email);
                                    setLeadUserName(foundUser.attributes.name);
                                } else {
                                    setLeadUserEmail(newValue);
                                }
                            }}
                            onChange={(event, selected) => {
                                if (selected) {
                                    setLeadUserEmail(selected.attributes.email);
                                    setLeadUserName(selected.attributes.name);
                                } else {
                                    setLeadUserEmail('');
                                    setLeadUserName('');
                                }
                            }}
                            getOptionSelected={(option, value) => option && value && option.attributes.email === value.trim()}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    margin="normal"
                                    label="Lead user email"
                                    type="email"
                                    className={classNameLeadUserEmail()}
                                />
                            )}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Lead user name"
                            value={leadUserName}
                            onChange={event => setLeadUserName(event.target.value)}
                            margin="normal"
                            className={classNameLeadUserName()}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12} >
                    <Box pl={2}>
                        <Typography variant="h6">Survey Modules</Typography>
                        <Autocomplete
                            multiple
                            value={sheet ? sheet.attributes.modules : []}
                            options={['foodsafety', 'quality', 'hs', 'environment', 'dei']}
                            getOptionLabel={option => typeTranslated(option, intl)}
                            onChange={(event, selected) => {
                                setSheet(prevSheet => ({
                                    ...prevSheet,
                                    attributes: {
                                        ...prevSheet.attributes,
                                        modules: selected
                                    }
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    margin="normal"
                                    label="Modules"
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box py={2}>
                        <Tooltip title="Save (saves the sheet in its current state)" placement="top">
                            <Button onClick={handleSave} variant="contained" color="primary">
                                Save
                            </Button>
                        </Tooltip>
                        <Tooltip title="Submit (saves the sheet and also creates and updates sites and users)" placement="top">
                            <Button onClick={() => setSubmitDialogOpen(true)} variant="contained" color="secondary" style={{marginLeft: 10}}>
                                Submit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Saving (or submitting) is required before changes show up in print view">
                            <IconButton component={RouterLink} target="_blank" to={'/companies/'+companyUuid+'/survey-sheets/'+sheetUuid+'/print?siteGroups='+siteGroups.filter(sg => sg.expanded || sg.expanded === undefined).map(sg => (encodeURIComponent(sg.name))).join('|')}>
                                <PrintIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box py={2} textAlign="center">
                        <span style={{color: 'rgba(27, 182, 214, 0.4)', fontSize: '1.2rem'}}>◼</span> = existing
                        <span style={{color: 'rgba(146, 191, 32, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = new / edited
                        <span style={{color: 'rgba(223, 5, 65, 0.25)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = standard
                        <span style={{color: 'rgba(186, 85, 211, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = standard & existing
                        <span style={{color: '#d9d9d9', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = pending deletion
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Box py={2}>
                        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} variant="fullWidth" classes={{ root: classes.tabs, indicator: classes.indicator }}>
                            <Tab label="Sites" />
                            <Tab label="Multi-site Users" />
                            <Tab label="Demographics" />
                            <Tab label="Sites & Demographics" />
                        </Tabs>
                        <Box py={2}>
                            {tabContent()}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Dialog
                open={submitDialogOpen}
                onClose={() => {setSubmitDialogOpen(false)}}
            >
                <DialogTitle>Submit</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Submitting the survey sheet applies all changes to sites and users, are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setSubmitDialogOpen(false)}} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="secondary" autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
