import React, { useContext, useEffect } from "react";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { UserContext } from "../../Context/UserContext";
import BrcgsFreeResources from "./BrgsFreeResources";
import BrcgsAdminResources from "./BrcgsAdminResources";

export default function Resources() {
    const { updateTitle } = useContext(AppBarTitleContext);
    const { user } = useContext(UserContext);

    useEffect(() => {
        updateTitle(null);
    });

    const resources = (role) => {
        if ('brcgs-free' === role) {
            return <BrcgsFreeResources />
        } else if ('brcgs' === role) {
            return <div>TODO: BRCGS resources</div>
        } else if ('brcgs-admin' === role) {
            return <BrcgsAdminResources />
        } else if ('brcgs-cb' === role) {
            return <div>TODO: BRCGS-CB resources</div>
        } else if ('ssafe' === role) {
            return <div>TODO: SSAFE resources</div>
        } else if ('premium' === role || 'premium-admin' === role | 'admin' === role) {
            return <div>TODO: Premium resources</div>
        } else {
            return null;
        }
    }

    return resources(user.role);
}