import { Grid, Table, TableCell, TableBody, TableHead, TableContainer, TableRow, Typography, makeStyles, Box } from "@material-ui/core";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom"
import CompanyService from "../../Services/CompanyService";
import SiteService from "../../Services/SiteService";
import UserService from "../../Services/UserService";
import SurveySheetService from "../../Services/SurveySheetService";
import { NotificationContext } from "../../Context/NotificationContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import { typeTranslated } from "../Translation/Type";
import { useIntl } from "react-intl";
import { COUNTRIES } from "../../Constants/Countries";
import { DEMOGRAPHIC_TYPES } from "../../Constants/DemographicTypes";
import SurveyService from "../../Services/SurveyService";

const useStyles = makeStyles((theme) => ({
    new: {
        backgroundColor: 'rgba(146, 191, 32, 0.4)'
    },
    edited: {
        backgroundColor: 'rgba(146, 191, 32, 0.4)'
    },
    existing: {
        backgroundColor: 'rgba(27, 182, 214, 0.4)'
    },
    standard: {
        backgroundColor: 'rgba(223, 5, 65, 0.25)'
    },
    standardExisting: {
        backgroundColor: 'rgba(186, 85, 211, 0.4)'
    },
    deleted: {
        backgroundColor: '#d9d9d9',
    }
}));

export default function SurveySheets(props) {
    const classes = useStyles();
    const intl = useIntl();
    const { companyUuid, sheetUuid } = useParams();
    const location = useLocation();
    const querySiteGroupsShow = new URLSearchParams(location.search).get('siteGroups');
    const siteGroupsShow = querySiteGroupsShow ? querySiteGroupsShow.split('|') : null;

    const [survey, setSurvey] = useState(null);
    const [sites, setSites] = useState(null);
    const [users, setUsers] = useState(null);
    const [company, setCompany] = useState(null);
    const [sheet, setSheet] = useState(null);
    const [siteGroups, setSiteGroups] = useState([]);

    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };

    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    };

    const loadingDone = null !== sites && null !== users && null !== company && null !== sheet;

    useEffect(() => {
        // Show loading if at least one thing has not finished loading
        updateLoadingRef.current(!loadingDone);

        if (loadingDone) {
            // Trigger print menu after a few seconds to avoid render glitch
            // setTimeout(() => { window.print(); }, 2000);
        }
    }, [loadingDone]);

    useEffect(() => {
        CompanyService.company(companyUuid).then(response => {
            setCompany(response.data.data);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        });

        SiteService.allSites('name', null, {company: companyUuid}).then(response => {
            setSites(response);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        });

        UserService.allUsers('name', null, {company: companyUuid}).then(response =>{
            setUsers(response);
        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        })

        SurveySheetService.sheet(sheetUuid).then(response => {
            setSheet(response.data.data);

            const siteGroupsToSet = [];
            (response.data.data.attributes.siteGroups ? response.data.data.attributes.siteGroups : ['Default']).forEach((sgName, sgIndex) => {
                const sg = {name: sgName, sites: [], expanded: true};
                (response.data.data.attributes.sites ? response.data.data.attributes.sites : []).forEach(s => {
                    if (s.group === sgIndex || (s.group === null || s.group === undefined)) {
                        sg.sites.push({...s});
                    }
                });
                siteGroupsToSet.push(sg);
            });
            setSiteGroups(siteGroupsToSet);

            if (response.data.data.attributes.createdFromSurveyCombinedHash) {
                SurveyService.surveyByHash(response.data.data.attributes.createdFromSurveyCombinedHash).then(response => {
                    setSurvey(response.data);
                });
            }

        }).catch(error => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        })
    }, [companyUuid, sheetUuid])

    const classUserEmail = (index) => {
        const curUser = sheet.attributes.users[index];
        const foundUser = users && curUser && curUser.email ? users.find(u => u.attributes.email.toLowerCase() === curUser.email.toLowerCase().trim()) : undefined;

        if (foundUser) {
            return classes.existing;
        } else if (curUser && curUser.email && curUser.email.trim() !== '') {
            return classes.new;
        }
        return null;
    };

    const classUserName = (index) => {
        const curUser = sheet.attributes.users[index];
        const foundUser = users && curUser && curUser.name ? users.find(u => u.attributes.email.toLowerCase() === curUser.email.toLowerCase().trim()) : undefined;

        if (foundUser && foundUser.attributes.name === curUser.name) {
            return classes.existing;
        } else if (foundUser && curUser.name !== foundUser.attributes.name) {
            return classes.edited;
        } else if (curUser.name && curUser.name.trim() !== '') {
            return classes.new;
        }
        return null;
    };

    const classNameLeadUserEmail = () => {
        if (sheet.attributes.leadUserEmail && users && users.find(u => u.attributes.email.toLowerCase() === sheet.attributes.leadUserEmail.toLowerCase().trim())) {
            return classes.existing;
        } else if (sheet.attributes.leadUserEmail && sheet.attributes.leadUserEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classNameLeadUserName = () => {
        const foundUser = users && sheet.attributes.leadUserEmail ? users.find(u => u.attributes.email.toLowerCase() === sheet.attributes.leadUserEmail.toLowerCase()) : undefined;
        if (sheet.attributes.leadUserEmail && foundUser && sheet.attributes.leadUserName === foundUser.attributes.name) {
            return classes.existing;
        } else if (sheet.attributes.leadUserEmail && foundUser && sheet.attributes.leadUserName !== foundUser.attributes.name) {
            return classes.edited;
        } else if (sheet.attributes.leadUserEmail && sheet.attributes.leadUserEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classNameSiteLeadUserEmail = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundUser = users && curSite.siteContactEmail ? users.find(u => u.attributes.email.toLowerCase() === curSite.siteContactEmail.toLowerCase().trim()) : undefined;
        if (foundUser) {
            return classes.existing;
        } else if (curSite.siteContactEmail && curSite.siteContactEmail.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classNameSiteLeadUserName = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundUser = users && curSite.siteContactEmail ? users.find(u => u.attributes.email.toLowerCase() === curSite.siteContactEmail.toLowerCase().trim()) : undefined;
        if (foundUser && curSite.siteContactName === foundUser.attributes.name) {
            return classes.existing;
        } else if (foundUser && curSite.siteContactName !== foundUser.attributes.name) {
            return classes.edited;
        } else if (curSite.siteContactName && curSite.siteContactName.trim() !== '') {
            return classes.new;
        }
        return null;
    }

    const classSiteName = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite) {
            return classes.existing;
        } else if (sites) {
            return classes.new;
        }
        return null;
    };

    const classSiteIndustry = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && curSite.industry === foundSite.attributes.industry) {
            return classes.existing;
        } else if (foundSite && curSite.industry !== foundSite.attributes.industry) {
            return classes.edited;
        } else if (sites && curSite.industry) {
            return classes.new;
        }
        return null;
    };

    const classSiteCountry = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && curSite.countryCode === foundSite.attributes.countryCode) {
            return classes.existing;
        } else if (foundSite && curSite.countryCode !== foundSite.attributes.countryCode) {
            return classes.edited;
        } else if (sites && curSite.countryCode) {
            return classes.new;
        }
        return null;
    };

    const classSiteTarget = (sgIndex, siteIndex) => {
        const curSite = siteGroups[sgIndex].sites[siteIndex];
        if (curSite.deleted) {
            return null;
        }
        const foundSite = sites && curSite.name && curSite.name.trim() ? sites.find(s => s.attributes.value.toLowerCase() === curSite.name.toLowerCase().trim()) : undefined;
        if (foundSite && parseInt(curSite.totalTarget) === foundSite.attributes.totalTarget) {
            return classes.existing;
        } else if (foundSite && curSite.totalTarget !== foundSite.attributes.totalTarget) {
            return classes.edited;
        } else if (sites && curSite.totalTarget) {
            return classes.new;
        }
        return null;
    };

    const classNameDemographicOption = (i, j) => {
        if (sheet.attributes.demographics[i].deleted) {
            return null;
        }

        if (sheet.attributes.demographics[i].deletedOptions && -1 !== sheet.attributes.demographics[i].deletedOptions.indexOf(sheet.attributes.demographics[i].options[j])) {
            return classes.deleted;
        }
        const alias = sheet.attributes.demographics[i].alias;
        const option = sheet.attributes.demographics[i].options[j];

        // Find out if this was used in survey this sheet is based on
        const previousQuestion = sheet.attributes.createdFromSurveyCombinedHash && survey && survey.included ? survey.included.find(q => q.attributes.demographic === alias) : null;

        const optionUsedInLastSurvey = previousQuestion && previousQuestion.attributes.options && previousQuestion.attributes.options.find(o => o.attributes.value === option);
        const optionStandard = alias in DEMOGRAPHIC_TYPES && -1 !== DEMOGRAPHIC_TYPES[alias].options.indexOf(option);

        if (optionUsedInLastSurvey && optionStandard) {
            return classes.standardExisting;
        } else if (optionStandard) {
            return classes.standard;
        } else if (optionUsedInLastSurvey) {
            return classes.existing;
        }

        return classes.new;
    }

    if (!loadingDone) {
        return null;
    }

    return (
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant="h4">Survey Data Sheet</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Colors</Typography>
                    <span style={{color: 'rgba(27, 182, 214, 0.4)', fontSize: '1.2rem'}}>◼</span> = existing
                    <span style={{color: 'rgba(146, 191, 32, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = new / edited
                    <span style={{color: 'rgba(223, 5, 65, 0.25)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = standard
                    <span style={{color: 'rgba(186, 85, 211, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = standard & existing
                    <span style={{color: '#d9d9d9', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = pending deletion
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Survey Settings</Typography>
                    <dl>
                        <dt>Title:</dt>
                        <dd>{sheet.attributes.title}</dd>
                        <dt>Company:</dt>
                        <dd>{company.attributes.name}</dd>
                        <dt>Lead User</dt>
                        <dd><span className={classNameLeadUserName()}>{sheet.attributes.leadUserName}</span></dd>
                        <dt>Lead User Email</dt>
                        <dd><span className={classNameLeadUserEmail()}>{sheet.attributes.leadUserEmail}</span></dd>
                    </dl>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Survey Modules</Typography>
                    {sheet.attributes.modules ? <ul>{sheet.attributes.modules.map(modType => <li key={modType}>{typeTranslated(modType, intl)}</li>)}</ul> : null}
                </Grid>

                <Grid item xs={12}>
                    {(siteGroups ? siteGroups : []).map((sg, sgIndex) =>
                        <Fragment key={sgIndex}>
                            <Typography variant="h6"> Sites - {sg.name}</Typography>
                            {siteGroupsShow === null || -1 !== siteGroupsShow.indexOf(sg.name) ?
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Site</TableCell>
                                                <TableCell>Industry</TableCell>
                                                <TableCell>Country</TableCell>
                                                <TableCell align="right">Total Employees (approx.)</TableCell>
                                                <TableCell>Site Contact Name</TableCell>
                                                <TableCell>Site Contact Email</TableCell>
                                                <TableCell>Comments Access</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {sg && sg.sites ? sg.sites.map((site, siteIndex) =>
                                            <TableRow key={siteIndex} className={site.deleted ? classes.deleted : null}>
                                                <TableCell className={classSiteName(sgIndex, siteIndex)}>{site.name}</TableCell>
                                                <TableCell className={classSiteIndustry(sgIndex, siteIndex)}>{site.industry}</TableCell>
                                                <TableCell className={classSiteCountry(sgIndex, siteIndex)}>{site.countryCode && site.countryCode in COUNTRIES ? COUNTRIES[site.countryCode] : null}</TableCell>
                                                <TableCell className={classSiteTarget(sgIndex, siteIndex)} align="right">{site.totalTarget}</TableCell>
                                                <TableCell className={classNameSiteLeadUserName(sgIndex, siteIndex)}>{site.siteContactName}</TableCell>
                                                <TableCell className={classNameSiteLeadUserEmail(sgIndex, siteIndex)}>{site.siteContactEmail}</TableCell>
                                                <TableCell>{site.commentsAccess ? '✅' : '❌'}</TableCell>
                                            </TableRow>
                                        ) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            : <div style={{paddingTop: 6, paddingBottom: 6}}>...</div>}
                        </Fragment>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box py={2}>
                        <Typography variant="h6">Multi-site Users</Typography>
                        {sheet.attributes.users && sheet.attributes.users.length > 1 ?
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Sites</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sheet.attributes.users.map((curUser, i) =>
                                            <TableRow key={i}>
                                                <TableCell className={classUserName(i)}>{curUser.name}</TableCell>
                                                <TableCell className={classUserEmail(i)}>{curUser.email}</TableCell>
                                                <TableCell>
                                                    <ul>
                                                        {curUser.sites.map((s, j) => <li key={j}>
                                                            {s === 'all' ? 'All' : s}
                                                        </li>)}
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        : <span>N/A</span>}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box py={2}>
                        <Typography variant="h6">Demographics</Typography>
                        {sheet.attributes.demographics ? sheet.attributes.demographics.map((dem, i) =>
                            <Box px={2} key={i} className={dem.deleted ? classes.deleted : null}>
                                <Typography variant="subtitle1">{dem.title} - (alias: {dem.alias})</Typography>
                                {dem.options ?
                                    <Box px={3}>
                                        <Typography variant="body1">Options</Typography>
                                        <ul style={{marginTop: 0}}>
                                            {dem.options.map((o, j) => <li key={j}><span className={classNameDemographicOption(i, j)}>{o}</span></li>)}
                                        </ul>
                                    </Box>
                                : null}
                            </Box>
                        ) : null}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Sites & Demographics</Typography>
                    {sheet.attributes.sites && sheet.attributes.demographics ?
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Site</TableCell>
                                        {sheet.attributes.demographics.map((dem, i) => (
                                            <TableCell key={i}>{dem.title}</TableCell>
                                        ))}
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {sheet.attributes.sites.map((site, j) => (
                                        <TableRow key={j} className={site.deleted ? classes.deleted : null}>
                                            <TableCell>{site.name}</TableCell>
                                            {sheet.attributes.demographics.map((dem, k) => (
                                                <TableCell key={k} className={dem.deleted ? classes.deleted : null}>
                                                    {sheet.attributes.sites[j].demographics && dem.alias in sheet.attributes.sites[j].demographics ? sheet.attributes.sites[j].demographics[dem.alias] : null}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    : null }
                </Grid>
            </Grid>
        </Fragment>
    );
}