export default function SurveyTypeLabel(props) {

    const label = (type) => {
        switch(type) {
            case 'brcgs-free':
                return 'BRCGS Basic';

            case 'brcgs-legacy':
                return 'BRCGS Legacy';

            case 'brcgs':
                return 'BRCGS';

            case 'ssafe':
                return 'SSAFE';

            case 'premium':
                return 'Culture Excellence';

            default:
                return type;
        }
    }

    return (<span>{label(props.type)}</span>)
}
